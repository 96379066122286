$publicPath:'';

.page {
  width: 100%;
  .header-box {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../../../assets/img/tzh/know-header-bg.png") no-repeat
      center/cover;
    .search-box {
      display: flex;
      justify-content: center;
      padding: 32px 0;
      & > div {
        display: flex;
        justify-content: space-between;
        width: 542px;
        height: 48px;
        background: #ffffff;
        border-radius: 3px;
        input {
          outline: none;
          border: none;
          flex: 1;
          text-indent: 12px;
          border: 1px solid #d9d9d9;
          &::-webkit-input-placeholder {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.25);
          }
        }
        .btn {
          text-align: center;
          width: 98px;
          line-height: 48px;
          background: #09a666;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    & > div {
      width: 1100px;
      .myTabs {
        margin-bottom: 25px;
        /deep/.el-tabs {
          .el-tabs__header {
            margin: 0;
          }
          .el-tabs__nav-wrap::after {
            display: none;
          }
          .el-tabs__item {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000;
            &.is-active {
              color: #09a666;
            }
          }
          .el-tabs__active-bar {
            background-color: #09a666;
            border-radius: 6px;
            height: 4px;
          }
        }
      }
      .article-list {
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 700;
          line-height: 20px;
          color: #000000;
          margin-bottom: 20px;
          &::before {
            content: "";
            display: inline-block;
            position: relative;
            margin-right: 8px;
            top: 5px;
            width: 4px;
            height: 22px;
            background: #09a666;
            border-radius: 10px;
          }
        }
        ul {
          li {
            &::before {
              content: "";
              margin-right: 8px;
              display: inline-block;
              position: relative;
              width: 4px;
              height: 4px;
              top: 50%;
              transform: translateY(-100%);
              background: #09a666;
              opacity: 0.2;
            }
            overflow: hidden; //溢出隐藏
            white-space: nowrap; //禁止换行
            text-overflow: ellipsis; //...
            margin-bottom: 16px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
            transition: all 0.5s;
            span {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    /* margin: 32px 163px ; */
  }
}
